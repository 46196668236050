import React from 'react'
import SignupButton from '../../components/SignupButton'
import Layout from '../../layouts/index'
import styles from './shared.module.css'

import Chrome from '../../components/Chrome'
import heroImage from '../../../assets/feedback-portal-large.png'
import votingImage from '../../../assets/feedback-detail/voting.png'
import commentsImage from '../../../assets/feedback-detail/comments.png'
import emailImage from '../../../assets/feedback-detail/email.png'

const LearnFeedbackPage = () => (
  <Layout>
    <div className="page">
      <div className={styles.hero}>
        <h1>Collect, centralize and manage feedback</h1>
        <div className={styles.subtitle}>
          <p>
            Are you wasting time tracking feedback across emails, trello and
            your CRM? Centralise everything in one place
          </p>
        </div>
        <Chrome>
          <img
            className={styles.heroImage}
            src={heroImage}
            alt="screenshot of a feedback board"
          />
        </Chrome>
      </div>

      <h2 className="center-text">Well informed product development</h2>
      <div className={styles.feature}>
        <div className={styles.description}>
          <h3>Vote on features</h3>
          <p>
            Your customers can suggest new features, and everyone will be able
            to vote on them
          </p>
          <p>
            This allows you to quickly surface good ideas that are wanted by a
            large segment of your customers
          </p>
        </div>
        <div className={styles.image}>
          <img
            className={styles.votingImage}
            src={votingImage}
            alt="feedback with votes by customers"
          />
        </div>
      </div>

      <div className={styles.feature}>
        <div className={styles.description}>
          <h3>Get better insight into feedback</h3>
          <p>
            When users submit a piece of feedback, you can easily ask for
            clarification on exactly what they want
          </p>
          <p>
            You can have a conversation with the customer to get a better
            understanding of why they want a particular feature
          </p>
          <p>
            This means that both you and the customer are on the same page which
            building a new feature based on their feedback
          </p>
        </div>
        <div className={styles.image}>
          <img
            className={styles.commentsImage}
            src={commentsImage}
            alt="comments by customers"
          />
        </div>
      </div>

      <div className={styles.feature}>
        <div className={styles.description}>
          <h3>Keep customers in the loop</h3>
          <p>
            When you update the status of a feedback, for instance from "In
            progress" to "Complete", everyone who has voted for it will be
            notified
          </p>
          <p>
            This increases customer happiness, as they know you're listening to
            their feedback
          </p>
        </div>
        <div className={styles.image}>
          <img
            className={styles.commentsImage}
            src={emailImage}
            alt="comments by customers"
          />
        </div>
      </div>

      <div className="signup-container">
        <SignupButton />
      </div>
    </div>
  </Layout>
)

export default LearnFeedbackPage
